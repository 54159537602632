<template>
  <div class="carousel flex transparent" ref="Carousel">
    <div class="carousel-container">
      <div :style="containerStyle" class="carousel-container-image transparent" ref="Image"></div>
      <div class="carousel-container-action left">
        <button
          @click.prevent="animateImagesManually(false)"
          :disabled="actionDisabled"
          type="button"
          class="carousel-container-action-cta"
          ref="CtaPrevious"
        >
          <font-awesome-icon :icon="['fas', 'caret-left']" />
        </button>
      </div>
      <div class="carousel-container-content text-center">
        <div class="carousel-container-content-wrapper">
          <h1 class="carousel-container-content-wrapper-text title transparent" ref="Title">
            {{ title }}
          </h1>
          <h2 class="carousel-container-content-wrapper-text subtitle transparent" ref="Subtitle">
            {{ text.subtitle.currentText }}
          </h2>
          <a
            :href="$utils.getPageRoute(routes[cta.active].href)"
            :title="$t(routes[cta.active].description)"
            class="cristal-cta primary"
            ref="CtaMain"
          >
            <span>
              {{ $t(routes[cta.active].description) }}
            </span>
          </a>
        </div>
      </div>
      <div class="carousel-container-action right">
        <button
          @click.prevent="animateImagesManually(true)"
          :disabled="actionDisabled"
          type="button"
          class="carousel-container-action-cta transparent"
          ref="CtaNext"
        >
          <font-awesome-icon :icon="['fas', 'caret-right']" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      actionDisabled: false,
      image: {
        intervalId: null,
        active: 0,
        stock: [
          "/img/carousel/desktop-screens.jpg",
          "/img/carousel/desktop-code.jpg",
          "/img/carousel/desktop-electronics.jpg",
          "/img/carousel/desktop-tech-art.jpg"
        ]
      },
      text: {
        subtitle: {
          intervalId: {
            changeWord: null,
            changeLetter: []
          },
          currentText: null,
          active: 0,
          stock: [
            "home.carousel.subtitleA",
            "home.carousel.subtitleB",
            "home.carousel.subtitleC",
            "home.carousel.subtitleD"
          ]
        }
      },
      cta: {
        intervalId: null,
        active: 0
      }
    };
  },
  computed: {
    /*
     * @computed - containerStyle
     * Returns current background image to use
     */
    containerStyle() {
      const { stock, active } = this.image;
      return { backgroundImage: `url(${stock[active]})` };
    },
    /*
     * @computed - routes
     * Gets routes.internal from utils plugin
     */
    routes() {
      return this.$utils.routes.internal;
    },
    /*
     * @computed - title
     * Gets carousel titleB if subtitle starts with vocals, otherwise it is titleA
     */
    title() {
      const vocals = ["a", "e", "i", "o", "u"];
      const subtitle = this.text.subtitle.currentText;

      return subtitle && vocals.includes(subtitle.split("")[0])
        ? this.$t("home.carousel.titleB")
        : this.$t("home.carousel.titleA");
    }
  },
  methods: {
    /*
     * @function - animateEntry
     * Calls Carousel elements fade and translate stepped animation
     * @param - none
     */
    async animateEntry() {
      const { Carousel, Image, CtaPrevious, CtaNext, CtaMain, Title, Subtitle } = this.$refs;

      this.text.subtitle.currentText = this.$t(this.text.subtitle.stock[this.text.subtitle.active]);
      this.m_animateFade(Carousel, 1000, "in");

      this.m_animateFade(Image, 1000, "in");

      this.m_animateTranslation(CtaPrevious, 250, 100, 0, 0, 0, 1000, false);
      this.m_animateFade(CtaPrevious, 500, "in", 1000);

      this.m_animateTranslation(CtaNext, 250, -100, 0, 0, 0, 1000, false);
      this.m_animateFade(CtaNext, 500, "in", 1000);

      this.m_animateTranslation(CtaMain.$el, 250, 0, -100, 0, 0, 1250, false);
      this.m_animateFade(CtaMain.$el, 500, "in", 1250);

      this.m_animateTranslation(Title, 250, -100, 0, 0, 0, 1500, false);
      this.m_animateFade(Title, 500, "in", 1500);

      this.m_animateTranslation(Subtitle, 250, 100, 0, 0, 0, 1750, false);
      await this.m_animateFade(Subtitle, 500, "in", 1750);
      this.resetAnimationIntervals();
    },
    /*
     * @function - resetAnimationIntervals
     * This function sets or resets animation intervals
     * @param - none
     */
    resetAnimationIntervals() {
      if (this.text.subtitle.intervalId.changeWord) {
        clearInterval(this.text.subtitle.intervalId.changeWord);
      }
      if (this.image.intervalId) {
        clearInterval(this.image.intervalId);
      }
      if (this.cta.intervalId) {
        clearInterval(this.cta.intervalId);
      }

      this.text.subtitle.intervalId.changeWord = setInterval(this.animateSubtitle, 6000);
      this.image.intervalId = setInterval(this.animateImages, 6000);
      this.cta.intervalId = setInterval(this.animateCta, 6000);
    },
    /*
     * @function - animateImagesManually
     * This function updates to next or previous image, cta and text to be shown in the carousel when clicking on arrow cta
     * @param - next (Boolean): If TRUE show next image, otherwise show previous
     */
    animateImagesManually(next = true) {
      this.resetAnimationIntervals();
      this.animateSubtitle();
      this.animateImages(next);
      this.animateCta();
    },
    /*
     * @function - animateImages
     * This function updates to next or previous image to be shown in the carousel
     * @param - next (Boolean): If TRUE show next image, otherwise show previous
     */
    async animateImages(next = true) {
      const { stock, active } = this.image;
      const imageEl = this.$refs.Image;
      const total = stock.length - 1;
      const direction = next ? 1 : -1;

      this.actionDisabled = true; // Disable next and previous CTA's
      this.m_animateFade(imageEl, 300, "out", 0, false); // Fade out
      await this.m_animateTranslation(imageEl, 350, 0, 0, -direction * 600, 0, 0, false); // Translate in direction based if is next or previous

      if (next) {
        this.image.active = active < total ? active + 1 : 0; // New active if is next image
      } else {
        this.image.active = active > 0 ? active - 1 : total; // New active if is previous image
      }

      this.m_animateFade(imageEl, 300, "in", 0, false); // Fade in
      await this.m_animateTranslation(imageEl, 350, direction * 600, 0, 0, 0, 0); // Translate in direction based if is next or previous
      this.actionDisabled = false; // Enable next and previous CTA's
    },
    /*
     * @function - animateSubtitle
     * This function realizes fade out, random letters, fade in and restablish of real text for subtitle animation
     * @param - none
     */
    async animateSubtitle() {
      const { stock, active } = this.text.subtitle;
      const subtitleEl = this.$refs.Subtitle;
      const total = stock.length - 1;

      await this.m_animateFade(subtitleEl, 300, "out", 0, false);
      // Fade in
      const newActive = active < total ? active + 1 : 0; // New active
      const realTextArray = this.$t(stock[newActive]) && this.$t(stock[newActive]).split(""); // Text that is the one to be shown in array
      const randoms = "abcdefghijklmnñopqrstuvwxyz".split(""); // Random letters in array
      let text = "";

      for (let i = 0; i < realTextArray.length; i++) {
        text += randoms[Math.floor(Math.random() * randoms.length)]; // text var is filled with random letters based on real text length
      }

      this.clearSubtitleIntervals(); // New word interval, clear all letters intervals
      this.text.subtitle.active = newActive; // Active is updated
      this.text.subtitle.currentText = text; // Text is updated with randomized text

      await this.m_animateFade(subtitleEl, 300, "in", 0);
      // Fade in
      const intervalLetterId = setInterval(() => {
        // Interval to replace random letters with real ones
        const currentTextArray = this.text.subtitle.currentText.split(""); // Text that is being showed in array

        let i = 0;
        while (currentTextArray[i] === realTextArray[i] && i + 1 < realTextArray.length) {
          i++; // Increment i by one until random letter related to it as index is the same as the real one, or if i + 1 is equal to the real text length
        }
        currentTextArray.splice(i, 1, realTextArray[i]); // Replace i random letter with i real letter

        this.text.subtitle.currentText = currentTextArray.join(""); // Text is updated with the a letter fixed

        if (
          i + 1 === realTextArray.length ||
          this.text.subtitle.currentText === realTextArray.join("")
        ) {
          this.clearSubtitleIntervals(); // If currentText is what should be shown, or i + 1 is equal to the length of the real text, then clear all words intervals, we don't need it anymore
        }
      }, 50);

      this.text.subtitle.intervalId.changeLetter.push(intervalLetterId);
    },
    /*
     * @function - clearSubtitleIntervals
     * This function cleans all intervals related to changing subtitles letters transition
     * @param - none
     */
    clearSubtitleIntervals() {
      const { changeLetter } = this.text.subtitle.intervalId;
      for (let i = changeLetter.length - 1; i >= 0; i--) {
        clearInterval(changeLetter[i]);
        this.text.subtitle.intervalId.changeLetter.splice(i, 1);
      }
    },
    /*
     * @function - animateCta
     * This function updates to next or previous main cta to be shown in the carousel
     * @param - none
     */
    async animateCta() {
      const total = this.routes.length - 1;
      const ctaEl = this.$refs.CtaMain.$el;

      await this.m_animateFade(ctaEl, 300, "out", 0, false); // Fade out
      this.cta.active = this.cta.active < total ? this.cta.active + 1 : 0;
      this.m_animateFade(ctaEl, 300, "in", 0); // Fade in
    }
  },
  beforeDestroy() {
    clearInterval(this.text.subtitle.intervalId.changeWord);
    clearInterval(this.image.intervalId);
    clearInterval(this.cta.intervalId);
  }
};
</script>

<style lang="scss" scoped>
.carousel {
  @include boxShadow(0.65, true, false);
  background-color: $dark-purple;
  overflow: hidden;
  position: relative;
  z-index: 10;

  &-container {
    display: inherit;
    flex-wrap: wrap;
    height: calc(100vh - 7.25rem);
    margin-top: 3rem;
    width: 100%;
    position: relative;

    @include breakpoint($small) {
      height: calc(100vh - 8.25rem);
      margin-top: 3.25rem;
    }

    @include breakpoint($large) {
      flex-wrap: nowrap;
      height: calc(100vh - 9.5rem);
      margin-top: 3.75rem;
    }

    &-image {
      background: transparent no-repeat fixed;
      background-position: center;
      background-size: cover;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
    }

    &-action {
      display: inherit;
      flex: 1 0 50%;
      height: 20%;

      @include breakpoint($large) {
        flex: 1 0 10%;
        height: 100%;
      }

      &.right {
        order: 3;

        .carousel-container-action-cta {
          border-left: 0.0625rem solid $white-gray;

          &:hover {
            border-left-color: $live-blue;
          }
        }
      }

      &.left {
        order: 2;

        @include breakpoint($large) {
          flex: 1 0 10%;
          height: 100%;
          order: 1;
        }

        .carousel-container-action-cta {
          border-right: 0.0625rem solid $white-gray;

          &:hover {
            border-right-color: $live-blue;
          }
        }
      }

      &-cta {
        @include fillVerticalTransition(
          $white-gray,
          transparent,
          $live-blue,
          rgba($white-gray, 0.15),
          true
        );
        @include boxShadow(0, true, false);
        border: 0;
        border-radius: 0.5rem;
        display: inherit;
        font-size: 2rem;
        margin: auto;
        padding: 0.25rem 0.5rem;
        transition: box-shadow 0.75s ease, color 0.75s ease;

        @include breakpoint($large) {
          font-size: 4rem;
          padding: 0.5rem 1rem;
        }

        &:hover {
          @include boxShadow(0.45, true, false);
        }

        &::before {
          border-radius: 0.5rem;
        }

        .fas {
          margin: inherit;
        }
      }
    }

    &-content {
      display: inherit;
      flex: 1 0 100%;
      flex-wrap: wrap;
      height: 80%;
      order: 1;
      position: inherit;

      @include breakpoint($large) {
        flex: 1 0 80%;
        height: 100%;
        order: 2;
      }

      &-wrapper {
        margin: auto;

        &-text {
          @include boxShadow(0.65, true, false);
          background-color: rgba($dark-raw, 0.65);
          color: $live-blue;
          display: table;
          font-weight: bold;
          margin-left: 0.5rem;
          margin-right: 0.5rem;

          @include breakpoint($large) {
            margin-left: 0;
            margin-right: 0;
          }

          &.title {
            font-size: 2rem;
            letter-spacing: 0.5rem;
            line-height: 3.5rem;
            margin: 0 auto 1rem;
            padding: 0rem 1.5rem;

            @include breakpoint($large) {
              font-size: 3.5rem;
              letter-spacing: 0.75rem;
              line-height: 5rem;
              margin: 0 auto 1.5rem;
              padding: 0rem 3rem;
            }

            @include breakpoint($xlarge) {
              font-size: 4rem;
              line-height: 5rem;
              padding: 0rem 4rem;
            }

            @include breakpoint($xxlarge) {
              font-size: 5rem;
              letter-spacing: 1rem;
              line-height: 7rem;
            }
          }

          &.subtitle {
            letter-spacing: 0.25rem;
            line-height: 2.5rem;
            margin: 0 auto 1rem;
            padding: 0.2rem 1.5rem 0;
            word-break: break-all;

            @include breakpoint($large) {
              font-size: 2rem;
              letter-spacing: 0.3rem;
              line-height: 3.5rem;
              margin: 0 auto 1.5rem;
              padding: 0.5rem 3rem 0;
            }

            @include breakpoint($xlarge) {
              font-size: 2.5rem;
              letter-spacing: 0.45rem;
              line-height: 5rem;
            }

            @include breakpoint($xxlarge) {
              font-size: 4rem;
            }
          }
        }
      }
    }
  }
}
</style>
