<template>
  <div class="home">
    <!-- <in-progress :is-page="true" /> -->
    <carousel />
    <data-container
      :title="$t('home.containers.aboutMe.title')"
      :firstParagraph="$t('home.containers.aboutMe.firstParagraph')"
      :secondParagraph="$t('home.containers.aboutMe.secondParagraph')"
      :anchorText="$t('navigation.aboutMe')"
      href="/acerca-de-mi/"
      color="tertiary"
      direction="bottom"
      position="left"
    />
    <data-container
      :title="$t('home.containers.myInterests.title')"
      :firstParagraph="$t('home.containers.myInterests.firstParagraph')"
      :secondParagraph="$t('home.containers.myInterests.secondParagraph')"
      :anchorText="$t('navigation.myInterests')"
      href="/mis-intereses/"
      color="primary"
      direction="doubled"
      :position="screenIsLargeOrLarger ? 'right' : 'left'"
    />
    <data-container
      :title="$t('home.containers.portfolio.title')"
      :firstParagraph="$t('home.containers.portfolio.firstParagraph')"
      :secondParagraph="$t('home.containers.portfolio.secondParagraph')"
      :anchorText="$t('navigation.portfolio')"
      href="/portafolio/"
      color="secondary"
      direction="top"
      position="left"
    />
    <data-container
      :title="$t('home.containers.contact.title')"
      :firstParagraph="$t('home.containers.contact.firstParagraph')"
      :secondParagraph="$t('home.containers.contact.secondParagraph')"
      :anchorText="$t('navigation.contact')"
      href="/contacto/"
      color="tertiary"
      direction="doubled"
      :position="screenIsLargeOrLarger ? 'right' : 'left'"
    />
  </div>
</template>

<script>
const Carousel = () => import(/* webpackChunkName: "HomePage" */ "@/components/Home/Carousel.vue");
const DataContainer = () =>
  import(/* webpackChunkName: "HomePage" */ "@/components/Home/DataContainer.vue");
// const InProgress = () =>
//   import(/* webpackChunkName: "InProgress" */ "@/components/Global/InProgress.vue");

export default {
  name: "Home",
  components: {
    Carousel,
    DataContainer
    // InProgress
  },
  computed: {
    /*
     * @computed - screenIsLargeOrLarger
     * TRUE if screen is large or larger width
     */
    screenIsLargeOrLarger() {
      return this.$store.state.Global.window.width >= this.$utils.styles.breakpoints.large;
    }
  }
};
</script>
