var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"DataContainer",staticClass:"data-container flex"},[_c('div',{ref:"Wrapper",class:[
      'data-container-wrapper transparent',
      _vm.$props.color,
      _vm.$props.direction,
      _vm.$props.position
    ],style:(_vm.containerStyle)},[_c('div',{class:['data-container-wrapper-colored flex', _vm.$props.color, _vm.$props.position]},[_c('div',{staticClass:"data-container-wrapper-colored-text"},[(!!_vm.$props.title)?_c('h2',{ref:"Title",class:[
            'data-container-wrapper-colored-text-title text-center transparent',
            _vm.$props.color
          ]},[_vm._v(" "+_vm._s(_vm.$props.title)+" ")]):_vm._e(),(!!_vm.$props.firstParagraph)?_c('p',{ref:"FirstParagraph",staticClass:"data-container-wrapper-colored-text-paragraph text-justify transparent"},[_vm._v(" "+_vm._s(_vm.$props.firstParagraph)+" ")]):_vm._e(),(!!_vm.$props.secondParagraph)?_c('p',{ref:"SecondParagraph",staticClass:"data-container-wrapper-colored-text-paragraph text-justify transparent"},[_vm._v(" "+_vm._s(_vm.$props.secondParagraph)+" ")]):_vm._e()])]),_c('div',{class:['data-container-wrapper-transparent flex', _vm.$props.position]},[_c('a',{ref:"CtaReadMore",class:[
          'data-container-wrapper-transparent-cta general-cta text-center transparent',
          _vm.$props.color
        ],attrs:{"href":_vm.$utils.getPageRoute(_vm.$props.href),"title":_vm.$props.anchorText}},[_c('span',[_vm._v(" "+_vm._s(_vm.$props.anchorText)+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }