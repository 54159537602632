<template>
  <div ref="DataContainer" class="data-container flex">
    <div
      :style="containerStyle"
      :class="[
        'data-container-wrapper transparent',
        $props.color,
        $props.direction,
        $props.position
      ]"
      ref="Wrapper"
    >
      <div :class="['data-container-wrapper-colored flex', $props.color, $props.position]">
        <div class="data-container-wrapper-colored-text">
          <h2
            v-if="!!$props.title"
            :class="[
              'data-container-wrapper-colored-text-title text-center transparent',
              $props.color
            ]"
            ref="Title"
          >
            {{ $props.title }}
          </h2>
          <p
            v-if="!!$props.firstParagraph"
            class="data-container-wrapper-colored-text-paragraph text-justify transparent"
            ref="FirstParagraph"
          >
            {{ $props.firstParagraph }}
          </p>
          <p
            v-if="!!$props.secondParagraph"
            class="data-container-wrapper-colored-text-paragraph text-justify transparent"
            ref="SecondParagraph"
          >
            {{ $props.secondParagraph }}
          </p>
        </div>
      </div>
      <div :class="['data-container-wrapper-transparent flex', $props.position]">
        <a
          :href="$utils.getPageRoute($props.href)"
          :title="$props.anchorText"
          :class="[
            'data-container-wrapper-transparent-cta general-cta text-center transparent',
            $props.color
          ]"
          ref="CtaReadMore"
        >
          <span>
            {{ $props.anchorText }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataContainer",
  props: {
    /*
     * @property - href
     * Sets page to move for read more cta
     */
    href: {
      type: String,
      required: true
    },
    /*
     * @property - anchorText
     * Sets anchor text of component
     */
    anchorText: {
      type: String,
      required: true
    },
    /*
     * @property - title
     * Sets title of component
     */
    title: {
      type: String,
      default: ""
    },
    /*
     * @property - firstParagraph
     * Sets first paragraph of component
     */
    firstParagraph: {
      type: String,
      default: ""
    },
    /*
     * @property - secondParagraph
     * Sets second paragraph of component
     */
    secondParagraph: {
      type: String,
      default: ""
    },
    /*
     * @property - color
     * Sets color style for background
     */
    color: {
      type: String,
      default: "primary"
    },
    /*
     * @property - direction
     * Sets direction style for background
     */
    direction: {
      type: String,
      default: "bottom"
    },
    /*
     * @property - position
     * Sets position style for background
     */
    position: {
      type: String,
      default: "left"
    }
  },
  data() {
    return {
      image: {
        active: 0,
        stock: [
          "/img/dataContainer/desktop-architectural-design.jpg",
          "/img/dataContainer/desktop-architectural-design-2.jpg",
          "/img/dataContainer/desktop-architectural-design-3.jpg"
        ]
      }
    };
  },
  computed: {
    /*
     * @computed - containerStyle
     * Returns current background image to use
     */
    containerStyle() {
      const { stock, active } = this.image;
      return { backgroundImage: `url(${stock[active]})` };
    },
    /*
     * @computed - directionStep
     * Returns current direction step according component direction
     */
    directionStep() {
      return this.$props.position === "left" ? 1 : -1;
    }
  },
  methods: {
    /*
     * @function - animateEntry
     * Calls m_animateEntry to animate component according the configuration sended, to work in both mobile and desktop versions
     * @param - none
     */
    async animateEntry() {
      const { Wrapper, Title, FirstParagraph, SecondParagraph, CtaReadMore } = this.$refs;
      const { large } = this.$utils.styles.breakpoints;

      await this.m_animateFade(Wrapper, 1000, "in");
      this.m_animateEntry([
        {
          component: Wrapper,
          method: "animateComponent",
          operationBreakpoint: "LARGEROREQUAL",
          behavior: 65,
          boundary: large
        },
        {
          component: Title,
          method: "animateTitle",
          operationBreakpoint: "SHORTER",
          behavior: "bottom",
          boundary: large
        },
        {
          component: FirstParagraph,
          method: "animateFirstParagraph",
          operationBreakpoint: "SHORTER",
          behavior: "bottom",
          boundary: large
        },
        {
          component: SecondParagraph,
          method: "animateSecondParagraph",
          operationBreakpoint: "SHORTER",
          behavior: "bottom",
          boundary: large
        },
        {
          component: CtaReadMore,
          method: "animateCtaReadMore",
          operationBreakpoint: "SHORTER",
          behavior: "bottom",
          boundary: large
        }
      ]);
    },
    /*
     * @function - animateComponent
     * Calls DataContainer elements fade and translate stepped animation
     * @param - none
     */
    animateComponent() {
      this.animateTitle(0);
      this.animateFirstParagraph(250);
      this.animateSecondParagraph(500);
      this.animateCtaReadMore(750);
    },
    /*
     * @function - animateTitle
     * Calls Title element fade and translate stepped animation
     * @param - none
     */
    animateTitle(animationDelay = 0) {
      const { Title } = this.$refs;

      this.m_animateTranslation(
        Title,
        250,
        this.directionStep * 100,
        0,
        0,
        0,
        animationDelay,
        false
      );
      this.m_animateFade(Title, 500, "in", animationDelay);
    },
    /*
     * @function - animateFirstParagraph
     * Calls FirstParagraph element fade and translate stepped animation
     * @param - none
     */
    animateFirstParagraph(animationDelay = 0) {
      const { FirstParagraph } = this.$refs;

      this.m_animateTranslation(
        FirstParagraph,
        250,
        this.directionStep * 100,
        0,
        0,
        0,
        animationDelay,
        false
      );
      this.m_animateFade(FirstParagraph, 500, "in", animationDelay);
    },
    /*
     * @function - animateSecondParagraph
     * Calls SecondParagraph element fade and translate stepped animation
     * @param - none
     */
    animateSecondParagraph(animationDelay = 0) {
      const { SecondParagraph } = this.$refs;

      this.m_animateTranslation(
        SecondParagraph,
        250,
        this.directionStep * 100,
        0,
        0,
        0,
        animationDelay,
        false
      );
      this.m_animateFade(SecondParagraph, 500, "in", animationDelay);
    },
    /*
     * @function - animateCtaReadMore
     * Calls CtaReadMore element fade and translate stepped animation
     * @param - none
     */
    animateCtaReadMore(animationDelay = 0) {
      const { CtaReadMore } = this.$refs;

      if (CtaReadMore) {
        this.m_animateTranslation(
          CtaReadMore,
          250,
          -this.directionStep * 100,
          0,
          0,
          0,
          animationDelay,
          false
        );
        this.m_animateFade(CtaReadMore, 500, "in", animationDelay);
      }
    },
    /*
     * @function - setActiveImage
     * Defines randomized active background image for the component, according to data stock
     * @param - none
     */
    setActiveImage() {
      this.image.active = Math.floor(Math.random() * this.image.stock.length);
    }
  },
  created() {
    this.setActiveImage();
  }
};
</script>

<style lang="scss" scoped>
.data-container {
  overflow: hidden;

  &-wrapper {
    @include boxShadow(0.65, true, false);
    background: transparent no-repeat fixed;
    background-position: center;
    background-size: cover;
    display: inherit;
    flex-wrap: wrap;
    height: 60rem;
    position: relative;
    width: 100%;
    z-index: 0;

    @include breakpoint($small) {
      height: 50rem;
    }

    @include breakpoint($large) {
      height: 100vh;
    }

    &::before {
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    &.primary.bottom.left::before {
      background-image: linear-gradient(175deg, rgba($main-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($main-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($main-blue, 0.95) 67.5%, transparent 32.5%);
      }
    }

    &.primary.top.left::before {
      background-image: linear-gradient(185deg, rgba($main-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(95deg, rgba($main-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(97.5deg, rgba($main-blue, 0.95) 67.5%, transparent 32.5%);
      }
    }

    &.primary.doubled.left::before {
      background-image: linear-gradient(175deg, rgba($main-blue, 0.75) 65%, transparent 35%),
        linear-gradient(185deg, rgba($main-blue, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($main-blue, 0.75) 55%, transparent 45%),
          linear-gradient(95deg, rgba($main-blue, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($main-blue, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(97.5deg, rgba($main-blue, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.primary.bottom.right::before {
      background-image: linear-gradient(5deg, rgba($main-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($main-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -82.5deg,
          rgba($main-blue, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.primary.top.right::before {
      background-image: linear-gradient(-5deg, rgba($main-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-95deg, rgba($main-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -97.5deg,
          rgba($main-blue, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.primary.doubled.right::before {
      background-image: linear-gradient(5deg, rgba($main-blue, 0.75) 65%, transparent 35%),
        linear-gradient(-5deg, rgba($main-blue, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($main-blue, 0.75) 55%, transparent 45%),
          linear-gradient(-95deg, rgba($main-blue, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(-82.5deg, rgba($main-blue, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(-97.5deg, rgba($main-blue, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.secondary.bottom.left::before {
      background-image: linear-gradient(175deg, rgba($main-green, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($main-green, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          82.5deg,
          rgba($main-green, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.secondary.top.left::before {
      background-image: linear-gradient(185deg, rgba($main-green, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(95deg, rgba($main-green, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          97.5deg,
          rgba($main-green, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.secondary.doubled.left::before {
      background-image: linear-gradient(175deg, rgba($main-green, 0.75) 65%, transparent 35%),
        linear-gradient(185deg, rgba($main-green, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($main-green, 0.75) 55%, transparent 45%),
          linear-gradient(95deg, rgba($main-green, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($main-green, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(97.5deg, rgba($main-green, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.secondary.bottom.right::before {
      background-image: linear-gradient(5deg, rgba($main-green, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($main-green, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -82.5deg,
          rgba($main-green, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.secondary.top.right::before {
      background-image: linear-gradient(-5deg, rgba($main-green, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-95deg, rgba($main-green, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -97.5deg,
          rgba($main-green, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.secondary.doubled.right::before {
      background-image: linear-gradient(5deg, rgba($main-green, 0.75) 65%, transparent 35%),
        linear-gradient(-5deg, rgba($main-green, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($main-green, 0.75) 55%, transparent 45%),
          linear-gradient(-95deg, rgba($main-green, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
            -82.5deg,
            rgba($main-green, 0.75) 67.5%,
            transparent 32.5%
          ),
          linear-gradient(-97.5deg, rgba($main-green, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.tertiary.bottom.left::before {
      background-image: linear-gradient(175deg, rgba($live-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($live-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($live-blue, 0.95) 67.5%, transparent 32.5%);
      }
    }

    &.tertiary.top.left::before {
      background-image: linear-gradient(185deg, rgba($live-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(95deg, rgba($live-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(97.5deg, rgba($live-blue, 0.95) 67.5%, transparent 32.5%);
      }
    }

    &.tertiary.doubled.left::before {
      background-image: linear-gradient(175deg, rgba($live-blue, 0.75) 65%, transparent 35%),
        linear-gradient(185deg, rgba($live-blue, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(85deg, rgba($live-blue, 0.75) 55%, transparent 45%),
          linear-gradient(95deg, rgba($live-blue, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(82.5deg, rgba($live-blue, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(97.5deg, rgba($live-blue, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &.tertiary.bottom.right::before {
      background-image: linear-gradient(5deg, rgba($live-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($live-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -82.5deg,
          rgba($live-blue, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.tertiary.top.right::before {
      background-image: linear-gradient(-5deg, rgba($live-blue, 0.95) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-95deg, rgba($live-blue, 0.95) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(
          -97.5deg,
          rgba($live-blue, 0.95) 67.5%,
          transparent 32.5%
        );
      }
    }

    &.tertiary.doubled.right::before {
      background-image: linear-gradient(5deg, rgba($live-blue, 0.75) 65%, transparent 35%),
        linear-gradient(-5deg, rgba($live-blue, 0.75) 65%, transparent 35%);

      @include breakpoint($large) {
        background-image: linear-gradient(-85deg, rgba($live-blue, 0.75) 55%, transparent 45%),
          linear-gradient(-95deg, rgba($live-blue, 0.75) 55%, transparent 45%);
      }

      @include breakpoint($xlarge) {
        background-image: linear-gradient(-82.5deg, rgba($live-blue, 0.75) 67.5%, transparent 32.5%),
          linear-gradient(-97.5deg, rgba($live-blue, 0.75) 67.5%, transparent 32.5%);
      }
    }

    &-colored {
      flex: 1 0 100%;
      height: 65%;

      @include breakpoint($large) {
        flex: 1 0;
        height: 100%;
      }

      @include breakpoint($xlarge) {
        flex: 1 0 65%;
      }

      &.right {
        order: 2;
      }

      &.primary {
        color: $white-gray;
      }

      &-text {
        margin: auto 0.15rem;
        text-shadow: 0.125rem 0.125rem rgba($white-gray, 0.2);

        @include breakpoint($medium) {
          margin: auto 1rem;
        }

        @include breakpoint($large) {
          display: flex;
          flex-direction: column;
          margin: auto 3rem;
        }

        &-title {
          border-bottom: 0.05rem solid $dark-gray;
          font-size: 2rem;

          @include breakpoint($small) {
            font-size: 2.5rem;
          }

          @include breakpoint($medium) {
            font-size: 3rem;
          }

          @include breakpoint($xlarge) {
            font-size: 3.5rem;
          }

          &.primary {
            border-bottom-color: $white-gray;
          }
        }

        &-paragraph {
          @include breakpoint($small) {
            font-size: 1.25rem;
          }

          @include breakpoint($xlarge) {
            font-size: 1.5rem;
          }
        }
      }
    }

    &-transparent {
      flex: 1 0 100%;
      height: 35%;

      @include breakpoint($large) {
        flex: 1 0;
        height: 100%;
      }

      @include breakpoint($xlarge) {
        flex: 1 0 35%;
      }

      &.right {
        order: 1;
      }

      &-cta {
        margin: auto;
      }
    }
  }
}
</style>
